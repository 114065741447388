import { Button, Image } from "rebass";
import Pic from "../../../../src/images/pic.jpg";
import { Link } from "gatsby";
import * as React from 'react';
export default {
  Button,
  Image,
  Pic,
  Link,
  React
};